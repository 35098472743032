<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        :readOnly="true"
                        id="textbox_BaoCaoHDBenXeBieuDo_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>

        <bieu-do-vue
            :NgayGioHeThong="NgayGioHeThong"
            :data_from_api="data_from_api"
            :DataBieuDo="DataBieuDo"
            @ThayDoiSoGTVT="ThayDoiSoGTVT"
            @getBieuDo="getBieuDo"
        />

        <!-- <LoaddingVue :Model="Loadding" /> -->
    </div>
</template>

<script>
import router from "@/components/_Common/Components/router";
import LoaddingVue from "@/components/_Common/Components/Loadding.vue";
import SearchVue from "@/components/_Common/Components/Search.vue";
import BieuDoVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaBenXe/BieuDo.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

export default {
    // Permission: $PermText("BieuDo", "BCHoatDongBenXe"),
    layout: "application",
    components: {
        router,
        BieuDoVue,
        SearchVue,
        LoaddingVue,
        DxToolbar,
        DxItem,
    },
    data() {
        return {
            url_DsTinhSo: this.$i18n.t("url.DungChungDanhSachSo"),
            url_DsBenTheoSo: this.$i18n.t("url.DungChungDanhSachBenTheoSo"),
            url_BieuDoLuotXuatBen: this.$i18n.t(
                "url.BieuDo_LuotXuatBenBieuDo_HoatDongBenXe"
            ),
            url_BieuDoLuotXuatBenTBGio: this.$i18n.t(
                "url.BieuDo_LuotXuatBenTBGioBieuDo_HoatDongBenXe"
            ),
            url_NgayGioHeThong: this.$i18n.t("url.NgayGioHeThong"),
            Loadding: false,
            data_from_api: {
                DsTinhSo: [],
                DsBenTheoSo: [],
                load: 0,
            },
            DataBieuDo: null,
            NgayGioHeThong: null,
            breadcrumbsItems: [
                {
                    id: "breadcrums_BaoCaoHDBenXeBieuDo_BaoCaoThongKe",
                    text: "Báo cáo - thống kê",
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BaoCaoHDBenXeBieuDo_BaoCaoHDBenXeBieuDo",
                    text: "Báo cáo hoạt động của bến xe",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let NgayGioHeThong = await this.$Core.Api.BaoCaoBenXe(
                    this.url_NgayGioHeThong
                ).Get();
                this.NgayGioHeThong = NgayGioHeThong.Data.Data;

                // Lấy ds Tỉnh sở
                let DsTinhSo = await this.$Core.Api.BaoCaoBenXe(
                    this.url_DsTinhSo
                ).Get();
                this.data_from_api.DsTinhSo = DsTinhSo.Data.Data || [];
                if (this.data_from_api.DsTinhSo[0]?.IdSo == 0) {
                    this.data_from_api.DsTinhSo.splice(0, 1);
                }
                if (this.data_from_api.load == 0) {
                    if (this.LoaiTaiKhoan == this.$i18n.t("string.so")) {
                        this.ThayDoiSoGTVT(
                            this.$Core.Auth.TokenParsed.CauHinhCu.MaSoDonVi
                        );
                    } else {
                        this.ThayDoiSoGTVT(
                            this.data_from_api.DsTinhSo[0]?.IdSo
                        );
                    }
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 112 ~ getData ~ error",
                    error
                );
            }
            // Lấy giờ hệ thống
        },
        async ThayDoiSoGTVT(id) {
            // Lấy ds bến theo Tỉnh sở
            try {
                let DsBenTheoSo = await this.$Core.Api.BaoCaoBenXe(
                    this.url_DsBenTheoSo,
                    {
                        idSos: id,
                    }
                ).Get();
                this.data_from_api.DsBenTheoSo = DsBenTheoSo.Data.Data || [];
                if (this.data_from_api.DsBenTheoSo[0]?.IdSo == 0) {
                    this.data_from_api.DsBenTheoSo.splice(0, 1);
                }
                this.data_from_api.load++;
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 134 ~ ThayDoiSoGTVT ~ error",
                    error
                );
            }
        },
        async getBieuDo(data, TenBieuDo) {
            try {
                this.$startLoading;
                this.DataBieuDo = null;
                let DataBieuDo = null;
                // Lấy ds bến theo Tỉnh sở
                if (TenBieuDo == "BDLuotXeXuatBen") {
                    DataBieuDo = await this.$Core.Api.BaoCaoBenXe(
                        this.url_BieuDoLuotXuatBen,
                        data
                    ).Get();
                } else if (TenBieuDo == "BDLuotKhachXuatBen_DenBen") {
                    DataBieuDo = await this.$Core.Api.BaoCaoBenXe(
                        this.url_BieuDoLuotXuatBen,
                        data
                    ).Get();
                } else if (TenBieuDo == "BDLuotXeXuatBen_DenBenTBTheoGio") {
                    DataBieuDo = await this.$Core.Api.BaoCaoBenXe(
                        this.url_BieuDoLuotXuatBenTBGio,
                        data
                    ).Get();
                }
                this.DataBieuDo = DataBieuDo.Data.Data;
                // this.DataBieuDo = {
                //     Data: {
                //         lst_BieuDo: [
                //             {
                //                 Title: "01/06",
                //                 KeHoach: 20,
                //                 TangCuong: 10,
                //                 CoDinh: 14
                //             },
                //             {
                //                 Title: "02/06",
                //                 KeHoach: 20,
                //                 TangCuong: 13,
                //                 CoDinh: 23
                //             },
                //             {
                //                 Title: "03/06",
                //                 KeHoach: 20,
                //                 TangCuong: 0,
                //                 CoDinh: 16
                //             },
                //             {
                //                 Title: "04/06",
                //                 KeHoach: 20,
                //                 TangCuong: 2,
                //                 CoDinh: 22
                //             },
                //             {
                //                 Title: "05/06",
                //                 KeHoach: 20,
                //                 TangCuong: 6,
                //                 CoDinh: 19
                //             }
                //         ],
                //         lst_ColName: [
                //             {
                //                 Id: "KeHoach",
                //                 Name: "Kế hoạch",
                //                 Stack: "1",
                //                 Show: true
                //             },
                //             {
                //                 Id: "TangCuong",
                //                 Name: "Tăng cường",
                //                 Stack: "2",
                //                 Show: true
                //             },
                //             {
                //                 Id: "CoDinh",
                //                 Name: "Cố định",
                //                 Stack: "2",
                //                 Show: true
                //             }
                //         ]
                //     }
                // };
            } catch (error) {
                console.log("🚀 ~ error", error);
            } finally {
                this.$stopLoading;
            }
        },
    },
    created() {
        this.getData();
    },
};
</script>
