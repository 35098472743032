<template>
    <div class="py-2 px-4">
        <div class="row justify-space-between align-center">
            <div class="xs8">
                <div class="row align-center">
                    <span
                        class="pr-3"
                        v-if="
                            LoaiTaiKhoan == $i18n.t('string.tongcuc') ||
                            LoaiTaiKhoan == $i18n.t('string.so')
                        "
                        >Sở GTVT:</span
                    >
                    <DxSelectBox
                        v-if="LoaiTaiKhoan == $i18n.t('string.tongcuc')"
                        v-model="itemSelectSoGTVT"
                        :dataSource="{
                            store: data_from_api.DsTinhSo,
                            paginate: true,
                        }"
                        displayExpr="TenSo"
                        valueExpr="IdSo"
                        @ItemClick="ThayDoiSoGTVT(itemSelectSoGTVT)"
                        :onValueChanged="onValueChangedSoGTVT"
                        :searchEnabled="true"
                        placeholder
                        id="dropdown_BaoCaoHDBenXeBieuDo_SoGTVT"
                        height="36"
                        stylingMode="underlined"
                    ></DxSelectBox>
                    <div
                        class="font-medium font-16"
                        v-if="LoaiTaiKhoan == $i18n.t('string.so')"
                    >
                        {{ $Core.Auth.TokenParsed.TenDonVi }}
                    </div>

                    <span class="px-3">Bến xe:</span>
                    <DxSelectBox
                        v-if="
                            LoaiTaiKhoan == $i18n.t('string.tongcuc') ||
                            LoaiTaiKhoan == $i18n.t('string.so')
                        "
                        v-model="itemSelectBenXe"
                        :dataSource="{
                            store: data_from_api.DsBenTheoSo,
                            paginate: true,
                        }"
                        displayExpr="TenBenXe"
                        valueExpr="IdBen"
                        :searchEnabled="true"
                        placeholder
                        id="dropdown_BaoCaoHDBenXeBieuDo_BenXe"
                        height="36"
                        stylingMode="underlined"
                    ></DxSelectBox>
                    <!-- @ItemClick="ThayDoiSoGTVT(itemSelectSoGTVT)" -->
                    <div
                        class="font-medium font-16"
                        v-if="LoaiTaiKhoan == $i18n.t('string.benxe')"
                    >
                        {{ $Core.Auth.TokenParsed.TenDonVi }}
                    </div>
                </div>
            </div>
            <div class="xs4">
                <div class="row justify-end">
                    <DxButton
                        id="dropdown_BaoCaoHDBenXeBieuDo_DieuHuongVeDanhSach"
                        class="ma-1"
                        icon="mdi mdi-format-list-checkbox"
                        @click="
                            $router.push(
                                '/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe'
                            )
                        "
                    />
                    <DxButton
                        id="dropdown_BaoCaoHDBenXeBieuDo_DieuHuongVeBieuDo"
                        class="ma-1 btn--active"
                        icon="mdi mdi-chart-bar"
                        style="margin-left: 6px"
                    />
                </div>
            </div>
        </div>

        <div class="row mt-3 px-3">
            <div class="frame-bieu-do" id="frame-bieu-do">
                <div class="row justify-center align-center">
                    <span class="mr-4 font-24 font-medium"
                        >Biểu đồ quan hệ</span
                    >
                    <DxSelectBox
                        v-model="itemSelectBieuDo"
                        :items="DsBieuDo"
                        displayExpr="TenBieuDo"
                        :searchEnabled="true"
                        placeholder
                        :onValueChanged="onValueChangedBieuDo"
                        id="dropdown_BaoCaoHDBenXeBieuDo_ChonBieuDo"
                        height="36"
                        width="450"
                        stylingMode="underlined"
                    ></DxSelectBox>
                    <!-- @ItemClick="ChonBieuDo(itemSelectBieuDo)" -->
                </div>
                <DxValidationGroup ref="formChonNgay">
                    <div class="mt-4 row justify-center align-center">
                        <span class="mr-3 font-16">Từ</span>
                        <DxDateBox
                            v-model="Model.TuNgay"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            :onContentReady="onDateBoxContentReady"
                            height="36"
                            width="160"
                            :onPaste="onPaste"
                            id="datebox_BaoCaoHDBenXeBieuDo_TuNgay"
                            validationMessageMode="always"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Từ ngày không được bỏ trống"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    message="Năm nhập không được vượt quá 9999!"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    message="Năm nhập không được nhỏ hơn 1800!"
                                />
                                <DxRangeRule
                                    :max="Model.DenNgay"
                                    message="Từ ngày không được lớn hơn Đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <div>
                                    <DxButton icon="mdi mdi-calendar" />
                                </div>
                            </template>
                        </DxDateBox>
                        <span class="mx-3 font-16" style="letter-spacing: -1px"
                            >----</span
                        >
                        <DxDateBox
                            v-model="Model.DenNgay"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            :onContentReady="onDateBoxContentReady"
                            height="36"
                            width="160"
                            :onPaste="onPaste"
                            id="datebox_BaoCaoHDBenXeBieuDo_DenNgay"
                            validationMessageMode="always"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Đến ngày không được bỏ trống"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    message="Năm nhập không được vượt quá 9999!"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    message="Năm nhập không được nhỏ hơn 1800!"
                                />
                                <!-- <DxRangeRule
                                    :min="Model.TuNgay"
                                    message="Đến ngày không được nhỏ hơn từ ngày"
                                />-->
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            id="button_BaoCaoHDBenXeBieuDo_LapBieuDo"
                            icon="mdi mdi-chart-bar"
                            styling-mode="outlined"
                            :rtlEnabled="true"
                            class="ml-4"
                            text="Lập biểu đồ"
                            height="36"
                            type="normal"
                            @click="LapBieuDo()"
                        />
                    </div>
                </DxValidationGroup>

                <div class="mt-4 content-bieu-do row" id="frame-chart">
                    <ChartVue :Model="Model" :Export="Export" />
                </div>

                <div class="mt-3 row justify-end align-center">
                    <DxCheckBox
                        v-if="!!Model.BarWithLine"
                        class="mr-4"
                        v-model="Model.showBien"
                        text="Hiện đường biên"
                    />
                    <DxCheckBox
                        v-model="Model.showLabel"
                        text="Hiển thị thông số trên biểu đồ"
                    />

                    <DxButton
                        id="button_BCHoatDongXeTimeline_Loc"
                        icon="mdi mdi-printer"
                        styling-mode="outlined"
                        :rtlEnabled="true"
                        class="mx-4"
                        text="In"
                        height="36"
                        type="normal"
                        @click="printChart"
                    />

                    <DxButton
                        id="button_BCHoatDongXeTimeline_Loc"
                        icon="mdi mdi-export"
                        styling-mode="outlined"
                        :rtlEnabled="true"
                        text="XUẤT ẢNH"
                        height="36"
                        type="normal"
                        @click="exportChart"
                    />
                </div>
            </div>
        </div>

        <!-- Thông báo -->

        <DxPopup
            v-model:visible="dialogThongBao"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="false"
            width="450"
            height="auto"
        >
            <NotificationVue :Params="Params" @save="hiddenDialog" />
        </DxPopup>
    </div>
</template>

<script>
import NotificationVue from "@/components/_Common/Popups/Notification.vue";
import ChartVue from "@/components/_Common/Components/Chart.vue";
import $ from "jquery";
import {
    DxSelectBox,
    DxButton,
    DxDateBox,
    DxCheckBox,
    DxValidator,
    DxPopup,
} from "devextreme-vue";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";

export default {
    components: {
        NotificationVue,
        ChartVue,
        DxSelectBox,
        DxButton,
        DxDateBox,
        DxCheckBox,
        DxValidator,
        DxPopup,
        DxValidationGroup,
        DxRangeRule,
        DxRequiredRule,
    },
    props: {
        data_from_api: { type: Object, default: {} },
        NgayGioHeThong: { type: String, default: null },
        DataBieuDo: { type: Object, default: null },
    },
    data() {
        return {
            maxDate: new Date("9999-12-31"),
            minDate: new Date("1800-01-01"),
            itemSelectSoGTVT: null,
            itemSelectBenXe: null,
            itemSelectBieuDo: null,
            dialogThongBao: false,
            Params: {
                state: "Error",
                title: "Thông báo!",
                icon: "mdi-alert-circle",
                color: "#D10909",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
                button: "ok",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
            },
            LoaiTaiKhoan: null,
            DsBieuDo: [
                {
                    id: "BDLuotXeXuatBen",
                    TenBieuDo: "Lượt xe xuất bến",
                    type: "stackedbar",
                    textX: "Ngày",
                    textY: "Lượt xuất bến",
                    LoaiBieuDo: 1,
                    BarWithLine: true,
                },
                {
                    id: "BDLuotKhachXuatBen_DenBen",
                    TenBieuDo: "Lượt khách xuất bến, đến bến",
                    type: "line",
                    textX: "Ngày",
                    textY: "Số khách",
                    LoaiBieuDo: 2,
                    BarWithLine: false,
                },
                {
                    id: "BDLuotXeXuatBen_DenBenTBTheoGio",
                    TenBieuDo: "Lượt xe xuất bến, đến bến trung bình theo giờ",
                    type: "bar",
                    textX: "Giờ",
                    textY: "Lượt xe",
                    LoaiBieuDo: 0,
                    BarWithLine: false,
                },
            ],
            // dataSource: [],
            // columnName: [],
            // widthContent: 500,
            // heightContent: 400,
            // showLabel: false,
            // typeBieuDo: "line",
            // TextX: null,
            // TextY: null,
            // TuNgay: null,
            // DenNgay: null,
            Model: {
                typeBieuDo: "line",
                TextX: null,
                TextY: null,
                TuNgay: null,
                DenNgay: null,
                showLabel: false,
                showBien: false,
                dataSource: [],
                columnName: [],
                MaxMinColumnName: [],
                widthContent: 500,
                heightContent: 500,
                BarWithLine: false,
            },
            Export: 0,
        };
    },
    watch: {
        data_from_api: {
            handler: function (val) {
                if (val.load == 0) {
                    if (!!val.DsTinhSo && val.DsTinhSo.length > 0) {
                        this.itemSelectSoGTVT = val.DsTinhSo[0].IdSo;
                    }
                }
                if (!!val.DsBenTheoSo && val.DsBenTheoSo.length > 0) {
                    this.itemSelectBenXe = val.DsBenTheoSo[0].IdBen;
                }
            },
            deep: true,
        },
        NgayGioHeThong: {
            // immediate: true,
            handler: function (val) {
                this.Model.TuNgay = null;
                this.Model.DenNgay = null;
                if (!!val) {
                    let date = new Date(val.substr(0, 10));
                    this.Model.TuNgay = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate() + 1 - 31,
                        7
                    );
                    this.Model.DenNgay = date;
                }
            },
            deep: true,
        },
        DataBieuDo: {
            // immediate: true,
            handler: function (val) {
                if (!!val) {
                    this.Model.dataSource = val.lst_BieuDo;
                    this.Model.columnName = val.lst_ColName;
                    if (!!val.lst_TenDuongBien) {
                        this.Model.MaxMinColumnName = val.lst_TenDuongBien;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        onPaste(e) {
            e.event.preventDefault();
        },
        onContentReady(e) {
            var html = e.component.content();
            $(html).css("padding", "8px");
        },
        onDateBoxContentReady(e) {
            // e.component.option("inputAttr", { readonly: true });
        },
        printChart() {
            // this.$refs.chart.instance.print();
            // console.log(
            //     "printChart -> this.$refs.chart.instance.print()",
            //     this.$refs.chart.instance.print()
            // );
            localStorage.setItem(
                "dataSource",
                JSON.stringify(this.Model.dataSource)
            );
            localStorage.setItem(
                "columnName",
                JSON.stringify(this.Model.columnName)
            );
            localStorage.setItem(
                "MaxMinColumnName",
                JSON.stringify(this.Model.MaxMinColumnName)
            );
            localStorage.setItem("typeBieuDo", this.Model.typeBieuDo);
            localStorage.setItem("TextX", this.Model.TextX);
            localStorage.setItem("TextY", this.Model.TextY);
            localStorage.setItem("TuNgay", this.Model.TuNgay);
            localStorage.setItem("DenNgay", this.Model.DenNgay);
            localStorage.setItem("widthContent", this.Model.widthContent);
            localStorage.setItem("heightContent", this.Model.heightContent);
            localStorage.setItem("showLabel", this.Model.showLabel);
            localStorage.setItem("BarWithLine", this.Model.BarWithLine);
            localStorage.setItem("showBien", this.Model.showBien);
            window.open("/Print/Print-Chart", "_blank");
        },
        exportChart() {
            this.Export++;
        },
        ThayDoiSoGTVT(IdSo) {
            console.log("ThayDoiSoGTVT -> IdSo", IdSo);
            this.$emit("ThayDoiSoGTVT", IdSo);
        },
        onValueChangedSoGTVT(e) {
            if (!e.value) {
                this.itemSelectBenXe = null;
                this.data_from_api.DsBenTheoSo = [];
            }
        },
        onValueChangedBieuDo(e) {
            if (!e.value) {
                setTimeout(() => {
                    this.itemSelectBieuDo = this.DsBieuDo[0];
                }, 100);
            }
        },
        hiddenDialog() {
            this.dialogThongBao = false;
        },
        // ChonBieuDo(itemSelectBieuDo) {
        //     this.typeBieuDo = itemSelectBieuDo.type;
        //     this.TextX = itemSelectBieuDo.textX;
        //     this.TextY = itemSelectBieuDo.textY;
        // },
        LapBieuDo() {
            let result = this.$refs.formChonNgay.instance.validate();
            let TuNgay = new Date(this.Model.TuNgay).getTime();
            let DenNgay = new Date(this.Model.DenNgay).getTime();

            let minusResult = DenNgay - TuNgay;
            this.Model.dataSource = [];
            this.Model.columnName = [];
            if (this.LoaiTaiKhoan == this.$i18n.t("string.benxe")) {
                this.itemSelectBenXe =
                    this.$Core.Auth.TokenParsed.CauHinhCu.MaSoDonVi;
            }
            if (!!result.isValid) {
                if (minusResult / (1000 * 60 * 60 * 24) + 1 > 31) {
                    this.dialogThongBao = true;
                    this.Params.message =
                        "Hệ thống chỉ hỗ trợ lập biểu đồ trong vòng 31 ngày!";
                } else {
                    let data = {
                        tuNgay: this.$Helper.ConvertToTimeTicks(
                            this.Model.TuNgay
                        ),
                        denNgay: this.$Helper.ConvertToTimeTicks(
                            this.Model.DenNgay
                        ),
                        idBen: this.itemSelectBenXe,
                        loaiBieuDo: this.itemSelectBieuDo.LoaiBieuDo,
                    };
                    this.Model.typeBieuDo = this.itemSelectBieuDo.type;
                    this.Model.TextX = this.itemSelectBieuDo.textX;
                    this.Model.TextY = this.itemSelectBieuDo.textY;
                    this.Model.BarWithLine = this.itemSelectBieuDo.BarWithLine;
                    this.$emit("getBieuDo", data, this.itemSelectBieuDo.id);
                }
            }
        },
    },
    created() {
        this.LoaiTaiKhoan = this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
        setTimeout(() => {
            this.Model.widthContent =
                document.getElementById("frame-chart").offsetWidth;
            this.Model.heightContent =
                document.getElementById("frame-bieu-do").offsetHeight -
                32 -
                36 -
                50 -
                24 -
                50 -
                16;
        }, 100);
        this.itemSelectBieuDo = this.DsBieuDo[0];
    },
};
</script>

<style scoped>
>>> .frame-bieu-do {
    box-shadow: 0px 19px 36px rgb(0, 0, 0, 0.16);
    width: 100%;
    height: calc(100vh - 56px - 60px - 32px);
    padding: 16px 24px;
}
@media screen and (max-width: 1365px) {
    >>> .frame-bieu-do {
        height: 100%;
    }
}
</style>
